<template>
  <div class="business-school">
    <!-- <Title title="海购云仓入门课"></Title> -->
    <!-- <div class="yc-school">
      <a href="https://hm.hmjx.cn/pdf/1.pdf"
        >海购云仓入门课1：微信小程序及公众号认证申请教程</a
      >
      <a href="https://hm.hmjx.cn/pdf/2.pdf"
        >海购云仓入门课程2：跨境知识培训课程</a
      >
      <a href="https://hm.hmjx.cn/pdf/3.pdf"
        >海购云仓入门课程3：商户店铺装修教程</a
      >
      <a href="https://hm.hmjx.cn/pdf/4.pdf"
        >海购云仓入门课程4：选品及商品知识教程</a
      >
    </div> -->
    <Title title="海购课程"></Title>
    <div class="main">
      <div class="left">
        <div
          class="item"
          @click="currentCourse = 0"
          :class="{ active: currentCourse == 0 }"
        >
          <span>海购云仓入门课</span>
        </div>
        <div
          class="item"
          @click="currentCourse = 1"
          :class="{ active: currentCourse == 1 }"
        >
          <span>抖音运营课：小白入门课</span>
        </div>
        <div
          class="item"
          @click="currentCourse = 2"
          :class="{ active: currentCourse == 2 }"
        >
          <span>抖音运营课：中阶基础课</span>
        </div>
        <div
          class="item"
          @click="currentCourse = 3"
          :class="{ active: currentCourse == 3 }"
        >
          <span>抖音运营课：高阶晋级课</span>
        </div>
        <div
          class="item"
          @click="currentCourse = 4"
          :class="{ active: currentCourse == 4 }"
        >
          <span>抖音运营课：终极达人课</span>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <div
            class="course"
            v-for="(i, x) in course[currentCourse].html"
            :key="x"
          >
            <div v-html="i"></div>
            <div
              class="btn"
              @click="$router.push('/settled')"
              v-if="!course[currentCourse].isPDF"
            >
              查看更多
            </div>
            <div
              class="btn"
              @click="openPDF(x + 1)"
              v-if="course[currentCourse].isPDF"
            >
              查看详情
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "@/components/Title.vue";
import pdf from "vue-pdf";
export default {
  name: "businessSchool",
  components: { Title, pdf },
  created() {
    console.log(this.course);
  },
  methods: {
    openPDF(index) {
       window.open(`https://hm.hmjx.cn/pdf/${index}.pdf`)
    },
  },
  data() {
    return {
      currentCourse: 0,
      course: [
        {
          html: [
            `<h2>海购云仓入门课1：微信小程序及公众号认证申请教程</h2>`,
            `<h2>海购云仓入门课程2：跨境知识培训课程</h2>`,
            `<h2>海购云仓入门课程3：商户店铺装修教程</h2>`,
            `<h2>海购云仓入门课程4：选品及商品知识教程</h2>`,
            `<h2>海购云仓入门课程5：2022年一月热点营销日历</h2>`,
            `<h2>海购云仓入门课程6：视频号运营教程</h2>`,
          ],
          isPDF: true,
        },
        {
          html: [
            `<h2>认识海购</h2>
      <p>1.关于海南跨境电商的政策及红利</p>
      <p>2.商免科技/商城平台/供应链体系</p>
      <p>3.公司团队实力现状/公司愿景规划/价值观</p>`,
            `<h2>私域流量存量运营</h2>
      <p>1.如何对微信生态个人社交圈做价值开发？</p>
      <p>2.如何精准快速获客？</p>
      <p>3.如何对种子用户实现流量裂变增长？</p>
      <p>4.如何对有效客户精细化运营？</p>`,
            `<h2>第一阶段：公域流量获客增量运营</h2>
      <p>1.认识抖音及各大流量平台</p>
      <p>2.了解抖音流量算法逻辑</p>
      <p>3.短视频账号搭建准备工作及基础条件</p>
      <p>4.抖音短视频达人号特点及如何搭建？</p>
      <p>5.抖音个人IP知识博主账号特点及如何搭建？</p>
      <p>6.抖音账号起步期运营技巧及细节</p>`,
          ],
          isPDF: false,
        },
        {
          html: [
            `<h2>第二阶段（短视频进阶流量运营）</h2>
      <p>1.如何获取精准流量和对账号进行打标签？</p>
      <p>2.如何突破短视频流量层级？</p>
      <p>3.爆款视频流量逻辑及如何剪出爆款视频？</p>
      <p>4.短视频内容的结构及拍摄制作技巧</p>
      <p>5.剪映的基础拍摄剪辑学习</p>`,
          ],
          isPDF: false,
        },
        {
          html: [
            `<h2>第三阶段（直播流量运营）</h2>
      <p>1.矩阵账号内容批量输出与搭建</p>
      <p>2.流量承接与转化</p>
      <p>3.直播引流技巧及开播准备内容</p>
      <p>4.直播间搭建及设备采买</p>
      <p>5.直播相关话术准备与直播优化</p>`,
          ],
          isPDF: false,
        },
        {
          html: [
            `<h2>第四阶段（直播带货）</h2>
      <p>1.直播带货团队搭建</p>
      <p>2.直播带货账号搭建与运营</p>
      <p>3.付费流量dou+玩法</p>
      <p>4.付费千川广告投放运营技巧</p>
      <p>5.付费结合自然流量运营技巧</p>`,
          ],
          isPDF: false,
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@media all and (min-width: 800px) {
  .business-school {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .main {
      display: flex;
      .left {
        width: 300px;
        background-color: #fbf4fa;
        font-size: 22px;
        .item {
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
          transition: all 0.2s;
          cursor: pointer;
          &:hover {
            // background-color: #bf34bb;
            background: linear-gradient(139deg, #dc31c0 0%, #8d34e5 100%);
            color: #fff;
          }
        }
        .active {
          // background-color: #bf34bb;
          background: linear-gradient(139deg, #dc31c0 0%, #8d34e5 100%);
          color: #fff;
        }
      }
      .right {
        flex: 1;
        .item {
          display: flex;
          flex-wrap: wrap;
          .course {
            background-color: #edeef5;
            // margin: 20px;
            padding: 20px;
            margin-left: 20px;
            margin-bottom: 20px;
            max-width: 370px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            div {
              text-align: left;
              /deep/ h2 {
                font-size: 25px;
              }
              /deep/ p {
                color: #333333;
                font-size: 18px;
                margin-top: 10px;
              }
            }

            .btn {
              border: 2px solid #bf34bb;
              color: #bf34bb;
              width: 70px;
              text-align: center;
              display: inline-block;
              padding: 5px 20px;
              border-radius: 20px;
              font-weight: 500;
              margin-top: 20px;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                background-color: rgb(255, 255, 255);
              }
            }
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .business-school {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .main {
      display: flex;
      flex-wrap: wrap;
      .left {
        width: 100%;
        background-color: #fbf4fa;
        font-size: 18px;
        margin-bottom: 20px;
        .item {
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          transition: all 0.2s;
          cursor: pointer;
          &:hover {
            background-color: #bf34bb;
            color: #fff;
          }
        }
        .active {
          background-color: #bf34bb;
          color: #fff;
        }
      }
      .right {
        width: 100%;
        .item {
          display: flex;
          flex-wrap: wrap;
          .course {
            background-color: #edeef5;
            // margin: 20px;
            padding: 20px;
            // margin-left: 20px;
            margin-bottom: 20px;
            // max-width: 370px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            div {
              text-align: left;
              /deep/ h2 {
                font-size: 24px;
              }
              /deep/ p {
                color: #333333;
                font-size: 18px;
                margin-top: 5px;
              }
            }

            .btn {
              border: 3px solid #bf34bb;
              color: #bf34bb;
              width: 70px;
              text-align: center;
              display: inline-block;
              padding: 5px 20px;
              border-radius: 20px;
              font-weight: 500;
              margin-top: 10px;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                background-color: rgb(255, 255, 255);
              }
            }
          }
        }
      }
    }
  }
}
.yc-school {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: #bf34bb;
    margin-top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>

