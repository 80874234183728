<template>
  <div class="settled">
    <h1>扫码添加我们的微信，加入我们！</h1>
    <img src="@/assets/qrcode3.jpeg" alt="" />
  </div>
</template>
<script>
// import Title from "@/components/Title.vue";
export default {
  components: {},
  name: "settled",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.settled {
    text-align: center;
    h1{
        margin: 40px;
    }
}
</style>

