<template>
  <div class="contact">
    <div class="banner-img">
      <img src="@/assets/contact/1.png" alt="" />
      <div class="content">
        <div class="box">
          <div class="left">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="center"></div>
          <div class="right">
            <p>地址：海南省海口美兰区海航互联网金融大厦A座503</p>
            <p>电话：0898-66550688</p>
            <!-- <p>邮箱：hmjx@hmjx.cn</p> -->
            <p>微信二维码</p>
            <img src="@/assets/qrcode3.jpeg" alt="" sizes="" srcset="" />
          </div>
        </div>
      </div>
    </div>
    <Title
      title="立即加入，与海购兔共享成长！"
      :textCenter="true"
      :purpleBg="true"
      :isShowBtnStyle="true"
      btnText="立即入驻"
    ></Title>
  </div>
</template>
<script>
import Title from "@/components/Title.vue";
export default {
  components: { Title },
  name: "contact",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@media all and (min-width: 800px) {
  .contact {
    .banner-img {
      width: 100%;
      position: relative;
      margin-bottom: 500px;
      img {
        width: 100%;
      }
      .content {
        position: absolute;
        bottom: -250px;
        height: 700px;
        // background-color: ;
        width: 100%;
        display: flex;
        justify-content: center;
        .box {
          width: 1200px;
          background-color: #fff;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          .left {
            width: 40%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 234px;
            }
          }
          .center {
            height: 80%;
            border: 1px solid #999999;
          }
          .right {
            box-sizing: border-box;
            padding-left: 80px;
            p {
              color: #666666;
              font-size: 22px;
              margin: 30px 0;
            }
            img {
              width: 250px;
            }
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .contact {
    .banner-img {
      width: 100%;
      img {
        width: 100%;
      }
      .content {
        // height: 500px;
        // background-color: ;
        width: 100%;
        display: flex;
        justify-content: center;
        .box {
          width: 100%;
          background-color: #fff;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
            img {
              width: 234px;
            }
          }
          .center {
            display: none;
          }
          .right {
            box-sizing: border-box;
            padding: 20px;
            p {
              color: #666666;
              font-size: 18px;
              margin: 15px 0;
            }
          }
        }
      }
    }
  }
}
</style>

