<template>
  <div class="news-info" v-html="news[newsId].content"></div>
</template>
<script>
export default {
  name: "newsInfo",
  data() {
    return {
      news: [
        {
          content: `<h2>自贸港｜海口海关这样提升外贸发展“硬实力”</h2>
        <p>国务院新闻办公室于2021年9月14日（星期二）上午10时举行新闻发布会，商务部副部长任鸿斌在会上提到，今年以来，全球新冠疫情肆虐，给全球的生产生活都带来了巨大冲击。但是中国的外贸依然展现出了强劲的韧性，实现较快增长，稳中向好。1-8月份，进出口规模达到24.78万亿元人民币，创历史新高，增速23.7%，也达到了10年来的高点。可以说外贸对国民经济贡献率持续提高，对国民经济的稳定恢复和发展作用更加凸显。在全球疫情和经贸环境异常复杂的情况下，取得这样的骄人成绩实属不易。在党中央、国务院坚强领导下，我们有效控制疫情，稳住了国内产业链供应链，加强国际经贸交流，广泛开展全球疫苗和防疫物资合作，积极满足国际市场需求，促进全球经济贸易稳定发展，这体现了中国广大外贸企业开拓创新和拼搏的精神，也体现了中国制造业的韧性和中国外贸的综合实力。距年底还有三个半月的时间，展望全年外贸，我们有信心通过努力完成全年“量稳质升”的目标任务。同时，我们要保持清醒和稳定，不能盲目乐观。调查问卷显示，在宏观数字高速增长的同时，我们要更加关心关注微观主体面临的风险挑战。外贸发展形势依然存在诸多不确定、不稳定和不均衡因素。</p>
        <p>一是国际市场需求面临较大不确定性。今年以来，全球疫情几度反复，当前单周新增确诊病例仍超过450万例，多国在“封锁”和“解封”中摇摆，世界经济复苏不确定性上升。同时，拉动外贸增长的阶段性因素或将消退，出口增速将逐步回落。调查显示，在手订单增长的外贸企业占比有所下降。</p>
        <p>二是全球产业链供应链不稳定性凸显。受新一轮德尔塔病毒影响，部分国家再次停工停产，导致芯片等大量中间产品生产受阻，严重影响了全球产业链供应链的稳定和畅通。同时，一些国家将贸易问题政治化，搞科技封锁，这也加大了外贸企业面临的产业链供应链风险。</p>
        <p>三是不同市场和企业主体开始分化。主要体现在三个方面：第一是新兴市场复苏动能不及传统市场，国际货币基金组织发布的7月最新报告上调了发达经济体增长预期0.5个百分点，下调新兴市场和发展中经济体增长预期0.4个百分点。第二是中小外贸企业订单状况不及大型外贸企业。国家统计局公布的8月制造业PMI显示，大型企业的新出口订单指数分别高于中型、小型企业0.7和3.5个百分点。第三是中下游外贸企业盈利能力不及上游企业。数据显示，上游石油、化工等行业利润总额增长情况普遍较好，而下游的纺织、服装、农副食品加工业利润增长幅度普遍偏低，这些因素都将影响外贸企业应对风险的能力。
</p>
        <p>近期调查还显示，中小外贸企业经营压力不断加大。主要面临两方面困难，一是海运运费居高不下，上海出口集装箱运价指数持续上涨至4568.16点，是去年4月低点的将近6倍，特别是去欧洲、美西、美东的运价是最高的。有轻工产品出口企业反映，出口运费已经接近或者超过了货值，造成了有单不敢接、有单不敢做的情况，一些产品生产了也是库存，无法按时发运。二是部分原材料价格仍在高位，8月PPI上涨9.5%，其中生产资料价格上涨12.7%，已连续8个月上涨，受此影响，中小外贸企业的利润受到严重挤压，面临较大困难。</p>
        <p>下一步，商务部将坚决贯彻党中央、国务院决策部署，立足新发展阶段、贯彻新发展理念，服务构建新发展格局，坚持“稳增长”“促创新”两手抓，密切跟踪研判国际市场形势变化，密切关注市场主体发展动态，做好政策储备，增强外贸抗风险能力，保持外贸跨周期平稳运行。一是适时推出针对性强的政策支持举措，加大对中小微外贸企业的帮扶力度。二是积极参与高标准的多边区域贸易安排，支持多边贸易体制。三是高质量共建“一带一路”，推进与更多贸易伙伴国建立贸易畅通工作机制、签署“丝路电商”合作协议。四是积极支持跨境电商、海外仓、市场采购、离岸贸易等外贸新业态新模式的发展。五是办好第130届广交会和第四届进口博览会等重要展会，与全球企业共享中国发展机遇，推动全球经济贸易早日复苏。</p>
       `,
        },
        {
          content: `<h2>《海口市促进跨境电子商务及国际快件产业发展暂行办法》</h2>
        <p>2018年4月13日，习近平总书记在庆祝海南建省办经济特区30周年大会上发表重要讲话，拉开了海南自由贸易港建设的序幕。借此春风，同年6月22日，本公众号创立，已经坚持了三个年头。本公众号是由几位热心于海南自由贸易港政策研究，热心于助力海南自由港建设的法律、财务、金融专业人士共同打造，为有共同兴趣和爱好的人士提供一个学习和了解海南自由贸易港政策的园地，为政策研究及制定者、国内外投资者、专业人士提供一个随时可以获取信息的资料库。</p>
        <p>2019年9月9日《海口市促进跨境电子商务及国际快件产业发展暂行办法》(以下简称“《办法》”)印发。海口将从支持跨境电商基础设施建设、支持跨境电商产业园区企业集聚、提高销售额等多方面发力，促进跨境电子商务发展。中小微跨境电商企业在线交易额达5万美元可申请扶持资金，单个企业资金扶持最高可达20万元人民币。</p>
        <p>《办法》提出，海口将积极支持跨境电子商务通关辅助设施和服务支撑体系建设，对现有及新建设的跨境电商基础设施进行补贴。每个申报主体给予基础设施和服务支撑体系建设费不超过50%资金支持，单个跨境电商监管点最高支持300万元人民币。同时，海口还将支持申报建设指定口岸。对成功获批并建成投运的口岸给予一次性资金扶持，单个口岸扶持资金最高可达500万元人民币。</p>
        <p>根据《办法》，海口还将打造跨境电商生态圈，支持跨境电商产业园区企业集聚。对经市级商务主管部门认定且经营满一年的跨境电子商务综合园区，入驻有跨境电子商务实绩的企业超过20家且跨境电商实绩达到5000万元人民币交易额，给予园区建设招商单位40万元人民币的一次性奖励；入驻有跨境电子商务实绩的企业超过40家且跨境电商实绩达到1亿元人民币的，给予园区建设招商单位80万元人民币的一次性奖励(含前档)。同时，还鼓励引进国内外知名、市场占有率高的跨境电商平台企业。对为跨境电子商务应用企业提供第三方电子商务平台服务，将总部或功能性区域中心设立或迁移至海口的，一年内带动海口50家小微企业注册上线或年交易额达3000万元人民币以上，给予最高30万元人民币的一次性奖励；最高超过带动1000家小微企业上线或达到年交易额4亿元人民币以上，将给予最高300万元的一次性奖励。</p>
        <p>同时，海口将鼓励各类跨境电子商务交易平台采取措施，提高跨境电子商务成交额，并培育发展中小微跨境电商企业。对成功申办、备案且年跨境电子商务在线交易额达5万美元以上的企业，按照每2万美元交易额给予1万元人民币标准的资金扶持，单个企业资金扶持最高可达20万元人民币。</p>
        <p>按照《办法》，海口今后将鼓励建设跨境电子商务O2O线下体验店，促进国际旅游消费中心建设。对在海南省内建立跨境电子商务O2O线下体验店或跨境电商体验区，且经营满一年，实现年交易额超过80万元的，按照每平方米350元人民币的标准给予一次性资金扶持，每家企业资金扶持最高为350万元人民币。</p>
        <img width="100%" src="https://qiniu.hmjx.cn/upload/1/common/images/20210926/20210926104216163262413693585.png"></img>
        `,
        },
        {
          content: `<h2>海口自贸参考 | 一文读懂海关跨境贸易便利化九大措施</h2>
        <p>近年来，海关不断优化口岸营商环境，主动作为、通力协作，不断深化“放管服”改革，出台了一系列政策措施，采取了一系列硬招实招，跨境贸易便利化水平逐年提升。为帮助广大进出口企业用足用好海关跨境贸易便利化措施，小编对相关政策措施进行了梳理解读，一起来看看！</p>
        <h3>Part 1：通关业务改革</h3>
        <h4>01.提前申报</h4>
        <p>问：什么是提前申报?</p>
        <p>答：经海关批准，进出口货物的收发货人、受委托的报关企业可以在取得提（运）单或者载货清单（舱单）数据后，向海关提前申报。</p>
        <p>在进出口货物的品名、规格、数量等已确定无误的情况下，经批准的企业可以在进口货物启运后、抵港前或者出口货物运入海关监管作业场所前3日内，提前向海关办理报关手续，并且按照海关的要求交验有关随附单证、进出口货物批准文件及其他需提供的证明文件。</p>
        <p>问：提前申报的部门规章和公告有哪些?</p>
        <p>答：《中华人民共和国进出口货物申报管理规定》（海关总署令第243号附件2）；</p>
        <p>《关于明确进出口货物提前申报管理要求的公告》（海关总署2014年第74号公告）。</p>
        <p>问：提前申报对企业有哪些便利?</p>
        <p>答：1.提升货物通关效率，优化口岸营商环境。提前申报是海关总署为促进贸易便利化推行的一项重要改革措施，有利于压缩货物通关时间，提升货物通关效率。
2.缩短货物在港时间，降低企业通关成本。企业进行提前申报，海关提前审核申报单证，企业根据船舶动态合理安排货物集港时间，货物运抵后自动触发报关单放行，货物装船出口，减少货物在港停留时间，降低企业通关成本。</p>
        <h4>02.两步申报</h4>
        <p>问：什么是两步申报?</p>
        <p>答：在“两步申报”通关模式下，企业不需要一次性填报所有申报项目，可分为概要申报及完整申报两步进行分别申报。</p>
        <p>具体分为两步</p>
        <p>第一步概要申报：对于不涉及进口禁限管制、检验或检疫的货物，企业只需申报9个项目，确认2个物流项目；对于涉及进口禁限管制或检验检疫的，分别增加申报2个或5个项目；应税的须选择符合要求的担保备案编号。如果货物不需查验，即可提离；涉税货物已经提交税款担保的，或需查验货物海关已完成查验的，也可以提离</p>
        <p>第二步完整申报：企业在货物提离后的规定时间内补充申报其他项目，办理缴纳税款等通关手续。</p>
        <p>答：《关于开展“两步申报”改革试点的公告》（海关总署公告2019年第127号）；</p>
        <p>《关于全面推广“两步申报”改革的公告》（海关总署公告2019年第216号）。</p>
        <p>问：两步申报对企业有哪些便利?</p>
        <p>答：“两步申报”进一步将海关监管环节“前推后移”，实现海关对物流的最小化干预甚至不干预，主要有以下优势：一是提货速度更快，减少了货物在码头的滞留时间，压缩了进口成本；二是有更充裕的时间准备报关资料，减少申报差错；三是企业可自行选择“两步申报”和原有模式，提升适应度和选择上的灵活自主性；四是“两步申报”为企业提供多元化的通关服务，有效降低企业在货物口岸通关中的经济成本和时间成本，进一步简化流程，提高通关效率。</p>
        <h4>03.简化报关单随附单证</h4>
        <p>问：什么是简化报关单随附单证?</p>
        <p>答：企业通过国际贸易“单一窗口”无纸化方式申报时，进口环节无需提交合同、装箱清单、载货清单（舱单），出口环节无需提交合同、发票、装箱清单、载货清单（舱单）。海关审核如有需要，再行以电子化方式提交或转有纸现场提交。</p>
        <p>问：简化报关单随附单证的公告、通告有哪些?</p>
        <p>答：各直属海关发布相关的公告和通告。如《黄埔海关关于简化报关单随附单证有关事项的通告》等。</p>
        <p>问：简化报关单随附单证对企业有哪些便利?</p>
        <p>答：大幅减少企业提交报关随附单证的种类和数量，有效缩短企业申报时的单证准备时间，提高通关效率。</p>
        <h4>04.两段准入</h4>
        <p>问：什么是两段准入?</p>
        <p>答：“两段准入”是指以进口货物准予提离进境口岸海关监管作业场所（含场地）为界，分段实施“是否允许货物入境”和“是否允许货物进入国内市场销售或使用”两类监管作业（分别简称“第一段监管”“第二段监管”）的海关监管方式。包括海关转场检查、附条件提离、合并检查等情形。</p>
        <p>问：“两段准入”的公告有哪些?</p>
        <p>答：《关于分段实施准入监管加快口岸验放的公告》（海关总署公告2019年第160号）。</p>
        <p>问：两段准入对企业有哪些便利?</p>
        <p>答：相关货物在进境地口岸海关完成必要的口岸检查，凭海关通知准予提离监管区，减少货物在口岸积压，可以有效降低滞港产生的仓储和时间成本，货物通关更加便利。</p>
        <h3>Part 2:税收征管方式改革</h3>
        <h4>05.汇总征税</h4>
        <p>问：什么是汇总征税？</p>
        <p>答：汇总征税是一种集约化的新型征税模式。该模式下，汇总征税符合条件的企业（纳税义务人）先向海关提供银行保函、关税保证保险、集团财务担保等税款总担保，由直属海关关税处进行担保备案；企业进口申报时在中国国际贸易“单一窗口”勾选汇总征税模式，总担保账户自动扣减应缴税额，先行办理货物放行手续，并于次月规定的缴款期限前对上月应纳税款汇总电子支付。</p>
        <p>问：汇总征税的公告有哪些?</p>
        <p>答：《海关总署关于优化汇总征税制度的公告》（海关总署公告2017年第45号）。</p>
        <p>问：汇总征税对企业有哪些便利?</p>
        <p>答：汇总征税把逐票审核、先税后放变为先放后税、汇总缴税。企业可先提取货物，海关则由实时逐票征税转化为后置税款结算。货物通关效率提高，通关成本下降。</p>
        <p>省力：拟实行汇总征税的企业应向属地直属海关关税职能部门申请。提供符合条件的担保并经海关备案后，企业即可在全国所有口岸海关汇总征税。实现一份保函、全国通用；一地备案、全国通行。</p>
        <p>省心：海关已开发专用的汇总征税作业系统，实现担保额度的智能化管理，根据企业税款缴纳情况循环使用。企业进口申报时，总担保账户自动扣减应缴税额；缴税后，担保额度自动恢复。</p>
        <p>省钱：企业可在提取货物后的规定缴税周期内自主汇总缴付税款，对缴税时间拥有更多的自主选择权。在税款备付资金方面，企业可以凭担保先行放行货物，减少这部分备付资金的占用，让企业资金使用的更加灵活。</p>
        <h4>06.关税保证保险等多元化税款担保</h4>
        <p>问：什么是关税保证保险？</p>
        <p>答：关税保证保险是指企业作为投保人提供由经保监会设立的保险公司出具的《关税保证保险单》，向海关申请办理通关手续的新型担保方式。企业凭保单办理纳税期限担保，海关对接受申报且满足全部放行条件的，即可实施现场卡口放行。</p>
        <p>问：关税保证保险的公告有哪些？</p>
        <p>答：《海关总署 银保监会关于开展关税保证保险通关业务试点的公告》（海关总署 银保监会公告2018第155号）；</p>
        <p>《关于关税保证保险应用于汇总征税和循环担保的公告》（海关总署公告2018第215号）。</p>
        <p>问：关税保证保险对企业有哪些便利?</p>
        <p>答：关税保证保险的推出，可充分释放保证金所占用的企业流动资金，避免银行保函占用企业的银行授信额度的占用，具有保费成本低、准入门槛低、通关时效强的特点。</p>
        <p>企业通关成本大幅降低。关税保证保险无需占用企业在银行的授信额度，也无需缴纳保证金，尤其适合在银行无授信或授信额度较少的中小微企业，大大降低了企业通关成本。</p>
        <p>企业准入门槛低。企业除了失信企业外，其他任何规模、类型的企业均可办理该业务。</p>
        <p>办理手续有效简化。保险公司开立关税保证保险单并将电子数据传给海关备案后即可使用。时效较快、手续简便。</p>
        <h4>07.自报自缴</h4>
        <p>问：什么是自报自缴?</p>
        <p>答：企业“自报自缴”是指进出口企业、单位自主向海关申报报关单及随附单证、税费电子数据，并自行缴纳税费。</p>
        <p>问：自报自缴的公告有哪些?</p>
        <p>答：《关于开展税收征管方式改革试点工作的公告》（海关总署公告2016年第62号）；</p>
        <p>《海关总署关于推广加工贸易料件内销征税自报自缴的公告》（海关总署公告2018年第196号）。</p>
        <p>问：自报自缴对企业有哪些便利?</p>
        <p>答：“自报自缴”与现有模式的最大区别在于货物放行时间提前，海关审核置后。“自报自缴”模式有利于进一步缩短货物通关时间，降低企业贸易成本。</p>
        <h3>Part 3:其他</h3>
        <h4>08.原产地证书自助打印</h4>
        <p>问：什么是原产地证书自助打印?</p>
        <p>答：原产地证书申请人或代理人可通过中国国际贸易“单一窗口”或“互联网+海关”一体化网上办事平台，自行打印海关审核通过的格式化原产地证书。</p>
        <p>问：原产地证书自助打印的公告有哪些?</p>
        <p>答：《关于全面推广原产地证书自助打印的公告》（海关总署公告2019年第77号）；
《关于扩大自助打印原产地证书范围的公告》（海关总署公告2020年第63号）；
《海关总署关于与泰国、毛里求斯互认自助打印优惠原产地证书的公告》（海关总署公告2021年第43号）。</p>
        <p>问：原产地证书自助打印对企业有什么便利?</p>
        <p>答：自助打印的原产地证书，具备海关和企业的电子签名、电子印章，可以实现打印、签名、盖章一次完成，真正实现申领原产地证全程电子化，使企业申领原产地证全程“足不出户” “零跑腿” “零接触”。</p>
        <h4>09.预裁定</h4>
        <p>问：什么是预裁定?</p>
        <p>答：在货物实际进出口前，申请人可以就下列与实际进出口活动有关的海关事务申请预裁定：进出口货物的商品归类；进出口货物的原产地或者原产资格；进口货物完税价格相关要素、估价方法；海关总署规定的其他海关事务。</p>
        <p>问：预裁定的部门规章和公告有哪些?</p>
        <p>答：《中华人民共和国海关预裁定管理暂行办法》（海关总署令第236号）；
《海关总署关于实施〈中华人民共和国海关预裁定管理暂行办法〉有关事项的公告》（海关总署公告2018年第14号）。</p>
        <p>问：预裁定对企业有哪些便利?</p>
        <p>答：企业在对进出口货物的商品归类、价格及原产地等存在疑虑时，可以及早进行预裁定申请，降低因各类技术性问题和不确定性可能引发的贸易合规风险，减少沟通成本，提高通关效率，增加贸易的可预期性。</p>
       `,
        },
        {
          content:`
            <h2>「商免科技」获千万元天使轮投资，海南跨境电商赛道首轮融资金额再突破</h2>
            <p>「商免科技」成立于2021年2月7日，是一家专业从事跨境电商的企业，通过聚焦海外供应链，将进口化妆品、护肤品、香氛等全球精品销往中国境内，为亿万消费者打造优质便捷的线上购物体验。</p>
            <p>近年来，跨境电商市场飞速发展。据《2021年(上)中国跨境电商市场数据报告》显示：2021上半年中国跨境电商市场规模6.05万亿元，预计2021年市场规模将达14.6万亿元。2021上半年中国跨境电商交易额占中国货物贸易进出口总值的33.48%，预计2021年渗透率将达40%。</p>
            <p>在《海南自由贸易港建设总体方案》的“零关税”和“一线”放开等政策扶持下，海南的跨境电商赢得了比内地更加优渥的发展条件：海南进行全岛封关运作前，就将对部分进口商品，免征进口关税、进口环节增值税和消费税。全岛封关运作、简并税制后，对进口征税商品目录以外、允许海南自由贸易港进口的商品，全部免征进口关税。商免科技也在不断释放的政策红利中嗅到了新的商机。</p>
            <p>“海购云仓管理平台”是商免科技历经近一年自主研发的一站式跨境电商服务系统，为跨境电商企业匹配零关税商品仓储资源和配送资源。商户通过后台入驻或API等模式接入云仓系统，即可通过海外供应链构建零关税产品渠道，以B2B2C的商业模式，绕开繁琐备货与物流等程序，直接触达消费者，完成产品和服务交付。</p>
            <img width="100%" src="https://qiniu.hmjx.cn/upload/1/common/images/20211207/20211207093011163884061162594.jpeg">
            <p>海购云仓的前台则是以小程序商城“海购兔”的形式展现给消费者，目前， “海购兔”线上购物平台共设有护肤、彩妆、香氛、彩妆工具和福利社5个商品类别，包括海蓝之谜、SK-II、雅诗兰黛、资生堂、娇韵诗、阿玛尼、圣罗兰等国际知名品牌，消费者无需登岛，即可购买零关税产品。商品价格由海购云仓系统统一定价，并从海南保税仓调配发货。据商免科技相关负责人介绍，海购兔平台中的零关税商品类别和数量将在后续的推广中进一步增加。</p>
            <img width="100%" src="https://qiniu.hmjx.cn/upload/1/common/images/20211207/20211207093011163884061123530.jpeg">
            <p>在商免科技的负责人看来，自贸港的多重政策扶持与后疫情时代的消费回流，让跨境电商企业迎来了最佳的入局时机，而“海购云仓”这一数字化供应链系统，也为跨境电商企业进入快车道创造了新机会。</p>
          `
        },{
          content: `
            <h2>免税也能共享？「海南商免」借云仓平台打开免税红利入口</h2>
            <p>“免税热”让海南跨境产业迎来黄金发展期，以海购云仓为代表的新型跨境电商模式，对海南跨境电商行业的多元化发展提供了更多可能，数字化平台也能让国人共享到海南自贸港的政策红利。</p>
            <p>“中国将在自由贸易试验区和海南自由贸易港做好高水平开放压力测试”，习主席11月4日在第四届上海进博会开幕式上的讲话，让对标世界最高水平开放形态的海南自由贸易港又一次备受瞩目。“开放“、”进口“、”消费“是本届进博会上的热词，折射在海南自由贸易港近年发展上的，则是免税经济掀起的浪潮。</p>
            <p>防疫常态化的后疫情时代，海外消费回流节奏加快。自2020年海南全岛被纳入跨境电商零售进口试点范围以来，免税业发展迎来新一轮提速。</p>
            <p>今年前三季度，海南消费增速位居全国前列。据海口海关的数据，2021年1月至9月海南离岛免税销售金额355亿元，较去年同期增长121%；购物旅客513万人次，增长84.5%；销售免税品5073万件，增长158%。</p>
            <p>同属于免税分支的零关税跨境电商在海南同样飞速发展。</p>
            <p>根据海口海关公布数据显示，2020年仅海口海关所属的马村港海关共监管放行跨境电商网购保税进口申报清125.5万单，总货值5.26亿元，同比分别增长635.3%、672.9%，具备极大的市场增长空间，未来有望成为海南自由贸易港建设中异军突起的另一支柱产业。</p>
            <p>离岛免税政策带来的消费聚焦，和疫情之下的出游困境，让“代购圈”掀起一股“海南热”。为了维护海南免税经济，去年7月6日，海关总署发布重新修订了《中华人民共和国海关对海南离岛旅客免税购物监管办法》，明确对“海代“进行界定和打击。</p>
            <p>以离岛为主要的条件的免税购物，和海关对代购的严格监管，让部分没有出行计划的人的购物需求无法得到释放，在此情况下，零关税跨境电商这种模式虽然在购买金额上虽然和离岛免税比起来有一定劣势，但其无需登岛即可购买，没有机票或船票要求。</p>
            <p>因此，虽然零关税跨境电商模式的单次金额不超过5000元，年度上限26000元的规定和离岛免税10万元每年的额度相比有一定差距，但也能一定程度上满足有购买免税品需求同时又不方便来海南的外省消费者的需求。</p>
            <p>疫情之下，海南零关税跨境电商行业方兴未艾。企查查数据显示，我国现存免税相关企业3.35万家，海南以1.58万家排名第一，政策红利与消费回流吸引着众多玩家不断入局，但海南跨境零关税产业的发展壮大，仍会受到采购成本、物流周期、供应链金融等因素的制约，在行业的痛点与竞争下，局中人和观望者都在思考如何乘借东风，站上海南免税经济的风口。</p>
            <p>免税红利如何共享？海南商免科技有限公司找到了“云”思路。</p>
            <p>「海南商免」瞄准免税赛道的需求，通过近一年的技术攻关，率先研发出了一套具备完全知识产权的海购云仓系统，为海南乃至全国范围内有意于海南零关税产品的创业者和消费者提供了一套完整的解决方案。</p>
            <p>用户通过接入海购云仓的SaaS系统，即可开一个自主命名的海南跨境零关税产品线上商城，对于原有一定用户的平台商家，也可以通过API接口对接的方式实现新增零关税商品业务的无缝融入，借助海购云仓的货品供应链和技术及运营支持，实现零成本抢占赛道先机。</p>
            <p>从仓储到物流，「海南商免」以数字化平台打开了海南免税红利入口，而在实际运营中如何帮助入局企业再创造差异化，对「海南商免」的技术开发实力、平台系统运营能力以及货品采购供应链的资金储备等方面也提出了更高的要求。</p>
            <p>“免税热”让海南跨境产业迎来黄金发展期，以海购云仓为代表的新型跨境电商模式，对海南跨境电商行业的多元化发展提供了更多可能，数字化平台也能让国人共享到海南自贸港的政策红利。</p>
          `
        }
      ],
      newsId: 0,
    };
  },
  created() {
    this.newsId = this.$route.query.id;
    console.log(this.$route.query.id);
  },
};
</script>

<style lang="less" scoped>
.news-info {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /deep/ h2 {
    text-align: center;
    font-size: 36px;
    margin-top: 30px;
  }
  /deep/ h3 {
    text-align: center;
    font-size: 34px;
    margin-top: 30px;
  }
  /deep/ h4 {
    text-align: center;
    font-size: 30px;
    margin-top: 30px;
  }
  /deep/ p {
    font-size: 20px;
    margin-top: 20px;
    text-indent: 2em;
    color: #666666;
  }
}
@media all and (max-width: 800px) {
  .news-info {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    /deep/ h2 {
      text-align: center;
      font-size: 24px;
      margin-top: 30px;
    }
    /deep/ h3 {
      text-align: center;
      font-size: 20px;
      margin-top: 30px;
    }
    /deep/ h4 {
      text-align: center;
      font-size: 18px;
      margin-top: 30px;
    }
    /deep/ p {
      font-size: 16px;
      margin-top: 20px;
      text-indent: 2em;
      color: #666666;
    }
  }
}
</style>

