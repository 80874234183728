import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Business from '../views/Business.vue'
import Index from '../views/Index.vue'
import BusinessSchool from '../views/BusinessSchool.vue'
import AttractInvestment from '../views/AttractInvestment.vue'
import News from '../views/News.vue'
import Contact from '../views/Contact.vue'
import Settled from '../views/Settled.vue'
import NewsInfo from '../views/NewsInfo.vue'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Home',
  component: Home,
  redirect: 'index',
  children: [{
      path: '/index',
      component: Index
    }, {
      path: '/business',
      component: Business
    },
    {
      path: '/businessSchool',
      component: BusinessSchool
    },
    {
      path: '/attractInvestment',
      component: AttractInvestment
    },
    {
      path: '/news',
      component: News
    },
    {
      path: '/contact',
      component: Contact
    },
    {
      path: '/settled',
      component: Settled
    },
    {
      path: '/newsInfo',
      component: NewsInfo
    }
  ]
}, ]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router