<template>
  <div class="home">
    <header-com></header-com>
    <router-view />
    <footer-com></footer-com>
  </div>
</template>

<script>
import HeaderCom from "@/components/HeaderCom.vue";
import FooterCom from "@/components/FooterCom.vue";
export default {
  components: { HeaderCom,FooterCom },
  name: "Home",
  watch: {
    '$route': function(to,from){
　    document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
};
</script>
