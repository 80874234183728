<template>
  <div class="footer">
    <div class="left">
      <div>
        <h4>关于我们</h4>
        <p @click="$router.push('/business')">公司简介</p>
        <p @click="$router.push('/settled')">联系我们</p>
        <p @click="$router.push('/settled')">加入我们</p>
      </div>
      <div>
        <h4>关于我们</h4>
        <p>电话：0898-66550688</p>
        <!-- <p>邮箱：hmjx@hmjx.cn</p> -->
        <p>地址：海南省海口美兰区海航互联网金融大厦A座503</p>
      </div>
    </div>
    <div class="right">
      <div>
        <img src="@/assets/qrcode1.png" alt="" />
        <p>关注微信公众号</p>
      </div>
      <div>
        <img src="@/assets/qrcode2.png" alt="" />
        <p>微信扫码进入小程序</p>
      </div>
    </div>
    <div class="bottom">
      <!-- <p>琼ICP备17135694号-2</p>
      <p>Copyright © 2021 海南商免科技有限公司. All rights reserved.</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="less">
@media all and (min-width: 800px) {
  .footer {
    // background-color: pink;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .left {
      display: flex;

      div {
        &:nth-child(1) {
          margin-right: 144px;
        }
        h4 {
          font-size: 22px;
          color: #333333;
        }
        p {
          margin-top: 27px;
          font-size: 21px;
          max-width: 280px;
          color: #999999;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            color: #666666;
          }
        }
      }
    }
    .right {
      display: flex;
      div {
        text-align: center;
        margin-left: 50px;
        img {
          width: 150px;
        }
      }
    }
    .bottom{
      width: 100%;
      color: #999999;
      text-align: center;
      margin-top: 50px;
    }
  }
}
@media all and (max-width: 800px) {
  .footer {
    // background-color: pink;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    .left {
      display: flex;
      flex-wrap: wrap;
      div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        padding-left: 10px;
        margin-bottom: 20px;
        &:nth-child(1) {
          // margin-right: 144px;
          // display: block;
        }
        h4 {
          font-size: 20px;
          color: #333333;
          display: block;
          width: 100%;
        }
        p {
          margin-top: 10px;
          margin-right: 10px;
          font-size: 16px;
          max-width: 280px;
          color: #999999;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            color: #666666;
          }
        }
      }
    }
    .right {
      display: flex;
      justify-content: space-around;
      padding: 0 10px;
      div {
        text-align: center;
        // margin-left: 50px;
        img {
          width: 150px;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
    .bottom{
      width: 100%;
      color: #999999;
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
