<template>
  <div class="business">
    <!-- <div class="banner-img">
      <img src="@/assets/business/1.png" alt="" />
    </div> -->
    <Title title="海南商免综合管理模块" :textCenter="true"></Title>
    <div class="content">
      <div class="left">
        <div class="title">公司简介</div>
        <div class="text">
          海南商免科技有限公司成立于2021年2月
          是自贸港免税政策全面落地后的首批专业从事 于跨境电商行业的企业之一。
          公司已取得包括海关备案，外管局备案
          建立海关账册，电信增值业务备案，对接海关
          监管系统在内的全套合规经营资质，达到全程 采用海关管理模式中最高级别的
          “三单对接” 标准。 公司拥有专业的运营团队，打造定制化解
          决方案。全球范国采购能力 ，卓越的供应链优
          势及货品保障。同时其专业投资和上市公司业
          务背景，对于该公司后续在资本市场的持续扩 张也有极强的推动作用。
        </div>
      </div>
      <div class="right">
        <img src="@/assets/business/2.jpg" alt="" />
      </div>
    </div>
    <Title title="团队构成" :textCenter="true"></Title>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/3.jpg" alt="" />
    </div>
    <Title
      title="四大核心产品及服务"
      :textCenter="true"
      :purpleBg="true"
    ></Title>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/4.jpg" alt="" />
    </div>
    <Title title="海购云仓平台优势" :textCenter="true"></Title>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/5.jpg" alt="" />
    </div>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/6.jpg" alt="" />
    </div>
    <Title title="海购云仓—定制化服务" :textCenter="true"></Title>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/7.jpg" alt="" />
    </div>
    <Title title="海购云仓运营服务" :textCenter="true"></Title>
    <div style="text-align: center">
      <img style="max-width: 1200px;width:100%" src="@/assets/business/8.jpg" alt="" />
    </div>
    <Title
      title="立即加入，与海购兔共享成长！"
      :textCenter="true"
      :purpleBg="true"
      :isShowBtnStyle="true"
      btnText="立即入驻"
    ></Title>
  </div>
</template>
<script>
import Title from "@/components/Title.vue";
export default {
  components: { Title },
  name: "business",
};
</script>
<style lang="less" scoped>
@media all and (min-width: 800px) {
  .business {
    .banner-img {
      width: 100%;
      // height: 688px;
      // background-color: rgb(255, 234, 238);
      img {
        width: 100%;
      }
    }
    .content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .left {
        width: 40%;
        .title {
          font-size: 33px;
          font-weight: 500;
        }
        .text {
          font-size: 22px;
          line-height: 34px;
          color: #999999;
          margin-top: 50px;
        }
      }
      .right {
        width: 60%;
        box-sizing: border-box;
        padding: 0 0 0 100px;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .business {
    .banner-img {
      width: 100%;
      // height: 688px;
      // background-color: rgb(255, 234, 238);
      img {
        width: 100%;
      }
    }
    .content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .left {
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        .title {
          font-size: 30px;
          font-weight: 500;
          text-align: center;
          margin-top: 20px;
        }
        .text {
          font-size: 20px;
          line-height: 30px;
          color: #999999;
          margin-top: 18px;
        }
      }
      .right {
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

