<template>
  <div class="news">
    <!-- <div class="banner-img">
      <img src="@/assets/news/1.png" alt="" />
    </div> -->
    <Title title="海购兔动态"></Title>
    <div class="main">
      <div class="item" v-for="(item, index) in newsList" :key="index">
        <div class="left"><img :src="item.img" alt="" /></div>
        <div class="right">
          <div class="right-top">
            <h2>{{ item.title }}</h2>
            <p>{{ item.summary }}</p>
          </div>
          <div class="right-bottom">
            <span>{{ item.time }}</span>
            <div
              @click="goNewsInfo(item.id)
              "
            >
              阅读详情
            </div>
          </div>
        </div>
      </div>
    </div>
    <Title
      title="立即加入，与海购兔共享成长！"
      :textCenter="true"
      :purpleBg="true"
      :isShowBtnStyle="true"
      btnText="立即入驻"
    ></Title>
  </div>
</template>
<script>
import Title from "@/components/Title.vue";
export default {
  components: { Title },
  name: "news",
  data() {
    return {
      newsList: [
        {
          title: "「商免科技」获千万元天使轮投资，海南跨境电商赛道首轮融资金额再突破",
          summary:
            "近日，海南商免科技有限公司（以下简称“商免科技”）已完成千万元天使轮融资，成为海南省内首家获千万级天使轮融资的跨境电商企业。据了解，本轮融资资金将用于产品的市场推广，并加速品牌在全国范围内的布局与运营。",
          time: "2021-12-06",
          id: 3,
          img: require('@/assets/news/3.jpeg'),
        },
        {
          title: "免税也能共享？「海南商免」借云仓平台打开免税红利入口",
          summary:
            "“免税热”让海南跨境产业迎来黄金发展期，以海购云仓为代表的新型跨境电商模式，对海南跨境电商行业的多元化发展提供了更多可能，数字化平台也能让国人共享到海南自贸港的政策红利。",
          time: "2021-11-22",
          id: 4,
          img: require('@/assets/news/3.jpeg'),
        },
        {
          title: "自贸港｜海口海关这样提升外贸发展“硬实力”",
          summary:
            "随着海南自贸港建设推进，外贸更富有活力。今年前两个月，海南外贸进出口161.7亿元，同比增长28.9%，外贸经济表现出良好势头。这背后是口岸营商环境持续优化，货物通关时长不断缩减，跨境贸易水平更加便利。",
          time: "2021-04-01",
          id: 0,
          img: "https://qiniu.hmjx.cn/upload/1/common/images/20210926/20210926104521163262432113277.png",
        },
        {
          title: "《海口市促进跨境电子商务及国际快件产业发展暂行办法》",
          summary:
            "2019年9月9日《海口市促进跨境电子商务及国际快件产业发展暂行办法》(以下简称“《办法》”)印发。海口将从支持跨境电商基础设施建设、支持跨境电商产业园区企业集聚、提高销售额等多方面发力，促进跨境电子商务发展。中小微跨境电商企业在线交易额达5万美元可申请扶持资金，单个企业资金扶持最高可达20万元人民币。",
          time: "2021-08-10",
          id: 1,
          img: "https://qiniu.hmjx.cn/upload/1/common/images/20210926/20210926104521163262432101680.jpg",
        },
        {
          title: "海口自贸参考 | 一文读懂海关跨境贸易便利化九大措施",
          summary:
            "近年来，海关不断优化口岸营商环境，主动作为、通力协作，不断深化“放管服”改革，出台了一系列政策措施，采取了一系列硬招实招，跨境贸易便利化水平逐年提升。为帮助广大进出口企业用足用好海关跨境贸易便利化措施，小编对相关政策措施进行了梳理解读，一起来看看！",
          time: "2021-08-3",
          id: 2,
          img: "https://qiniu.hmjx.cn/upload/1/common/images/20210926/20210926104834163262451477731.png",
        },
      ],
    };
  },
  methods:{
     goNewsInfo(id) {
      this.$router.push({path:'/newsInfo',query: {id:id}})
    },
  }
};
</script>

<style lang="less" scoped>
@media all and (min-width: 800px) {
  .news {
    margin: 0 auto;
    .banner-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .main {
      width: 1200px;
      margin: 0 auto;
      .item {
        display: flex;
        margin-top: 50px;
        .left {
          width: 540px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .right {
          background-color: #f1f4f7;
          flex: 1;
          box-sizing: border-box;
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .right-top {
            h2 {
              color: #333;
              font-size: 26px;
            }
            p {
              font-size: 18px;
              color: #666666;
              margin-top: 8px;
            }
          }
          .right-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            margin-top: 20px;
            span {
              color: #999999;
            }
            div {
              color: #707070;
              border: 1px solid #707070;
              border-radius: 20px;
              padding: 5px 15px;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                background-color: rgb(233, 233, 233);
              }
            }
          }
        }
        &:nth-child(odd) {
          .left {
            order: 2;
          }
          .right {
            order: 1;
          }
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .news {
    margin: 0 auto;
    .banner-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .main {
      width: 100%;
      margin: 0 auto;
      .item {
        display: flex;
        flex-wrap: wrap;
        width: 95%;
        margin: 20px auto;
        .left {
          width: 100%;
          img {
            width: 100%;
            vertical-align: middle;
          }
        }
        .right {
          width: 100%;
          background-color: #f1f4f7;
          box-sizing: border-box;
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .right-top {
            h2 {
              color: #333;
              font-size: 20px;
            }
            p {
              font-size: 13px;
              color: #666666;
              margin-top: 10px;
            }
          }
          .right-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            padding: 0 20px;
            span {
              color: #999999;
            }
            div {
              color: #707070;
              border: 1px solid #707070;
              border-radius: 20px;
              padding: 5px 15px;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                background-color: rgb(233, 233, 233);
              }
            }
          }
        }
      }
    }
  }
}
</style>

