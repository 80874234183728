<template>
  <div class="title" :class="{ 'title-purple': purpleBg, greyBg: greyBg }">
    <h1>{{ title }}</h1>
    <p :style="textCenter ? 'text-align:center' : ''">
      {{ text }}
    </p>
    <button v-if="isShowBtnStyle" :class="{ btn: isShowBtnStyle }"  @click="$router.push('/settled')">{{btnText}}</button>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: "",
    text: "",
    // 介绍文本是否居中，一般在字数太少时使用
    textCenter: false,
    // 紫色背景主题
    purpleBg: false,
    // 灰色背景主题
    greyBg: false,
    // 是否显示按钮样式
    isShowBtnStyle: false,
    btnText:''
  },
};
</script>

<style scoped lang="less">
@media all and (min-width: 800px) {
  .title {
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 38px;
      color: #333333;
      // text-align: center;
      margin-bottom: 20px;
      position: relative;
      display: block;

      &::after {
        content: "";
        position: absolute;
        left: -40px;
        top: -10px;
        width: 61px;
        height: 51px;
        background: url("~@/assets/index/2.png") no-repeat;
        background-size: cover;
        z-index: -1;
      }
    }
    p {
      color: #999999;
      width: 1000px;
      margin: 0 auto;
      font-size: 20px;
      line-height: 32px;
      // letter-spacing: 38px;
    }
    div {
      width: 154px;
      height: 43px;
      background-color: pink;
      border-radius: 100px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #c853c5;
      cursor: pointer;
      transition: all 0.2s;
    }
    button{
      font-size: 24px;
      background-color: #fff;
      color: #C853C5;
      border: none;
      padding: 5px 20px;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover{
        opacity: 0.9;
      }
    }
  }
  .title-purple {
    background: url("~@/assets/index/title_bg.png") no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    h1 {
      color: #fff;
      z-index: 2;
      &::after {
        background: url("~@/assets/index/3.png") no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }
    p {
      color: #fff;
    }
    div {
      background-color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .greyBg {
    background: #f2f5fa;
  }
}
@media all and (max-width: 800px) {
  .title {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 2rem;
      color: #333333;
      // text-align: center;
      margin-bottom: 20px;
      position: relative;
      display: block;
      text-align: center;
      max-width: 280px;
      box-sizing: border-box;
      &::after {
        content: "";
        position: absolute;
        left: -40px;
        top: -10px;
        width: 61px;
        height: 51px;
        background: url("~@/assets/index/2.png") no-repeat;
        background-size: cover;
        z-index: -1;
      }
    }
    p {
      color: #999999;
      width: 90%;
      margin: 0 auto;
      font-size: 1.2rem;
      line-height: 170%;
      // letter-spacing: 38px;
    }
    div {
      width: 154px;
      height: 43px;
      background-color: pink;
      border-radius: 100px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #c853c5;
      cursor: pointer;
      transition: all 0.2s;
    }
      button{
      font-size: 14px;
      background-color: #fff;
      color: #C853C5;
      border: none;
      padding: 5px 20px;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover{
        opacity: 0.9;
      }
    }
  }
  .title-purple {
    background: url("~@/assets/index/title_bg.png") no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    h1 {
      color: #fff;
      z-index: 2;
      &::after {
        background: url("~@/assets/index/3.png") no-repeat;
        background-size: cover;
        z-index: 1;
      }
    }
    p {
      color: #fff;
    }
    div {
      background-color: #fff;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .greyBg {
    background: #f2f5fa;
  }
}
</style>
