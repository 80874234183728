import { render, staticRenderFns } from "./AttractInvestment.vue?vue&type=template&id=233128c3&scoped=true&"
import script from "./AttractInvestment.vue?vue&type=script&lang=js&"
export * from "./AttractInvestment.vue?vue&type=script&lang=js&"
import style0 from "./AttractInvestment.vue?vue&type=style&index=0&id=233128c3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233128c3",
  null
  
)

export default component.exports