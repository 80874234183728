<template>
  <div>
    <div class="placeholder"></div>
    <div class="header">
      <div class="content">
        <div class="left">
          <img :src="require('@/assets/logo.png')" alt="" />
        </div>
        <div class="center">
          <router-link
            to="/"
            :class="{ active: currentActive == 0 }"
            @click.native="changeActive(0)"
            >首页</router-link
          >
          <router-link
            to="/business"
            :class="{ active: currentActive == 1 }"
            @click.native="changeActive(1)"
            >海购云仓</router-link
          >
          <router-link
            to="/businessSchool"
            class=""
            :class="{ active: currentActive == 2 }"
            @click.native="changeActive(2)"
            >海购商学院</router-link
          >
          <router-link
            to="/attractInvestment"
            class=""
            :class="{ active: currentActive == 3 }"
            @click.native="changeActive(3)"
            >招商合作</router-link
          >
          <router-link
            to="/news"
            class=""
            :class="{ active: currentActive == 4 }"
            @click.native="changeActive(4)"
            >海购新闻</router-link
          >
          <router-link
            to="/contact"
            class=""
            :class="{ active: currentActive == 5 }"
            @click.native="changeActive(5)"
            >联系我们</router-link
          >
        </div>
        <div class="right" @click="$router.push('/settled')">我要入驻</div>
      </div>
      <div class="phone">
        <div class="top">
          <div class="left">
            <img :src="require('@/assets/logo.png')" alt="" />
          </div>
          <div class="right" @click="showMenu()">
            <img src="@/assets/header/1.png" alt="" />
          </div>
        </div>
        <div class="menu" :class="{ show: isShowMenu }">
          <router-link
            to="/"
            :class="{ active: currentActive == 0 }"
            @click.native="changeActive(0)"
            >首页</router-link
          >
          <router-link
            to="/business"
            :class="{ active: currentActive == 1 }"
            @click.native="changeActive(1)"
            >海购业务</router-link
          >
          <router-link
            to="/businessSchool"
            class=""
            :class="{ active: currentActive == 2 }"
            @click.native="changeActive(2)"
            >海购商学院</router-link
          >
          <router-link
            to="/attractInvestment"
            class=""
            :class="{ active: currentActive == 3 }"
            @click.native="changeActive(3)"
            >招商合作</router-link
          >
          <router-link
            to="/news"
            class=""
            :class="{ active: currentActive == 4 }"
            @click.native="changeActive(4)"
            >海购新闻</router-link
          >
          <router-link
            to="/contact"
            class=""
            :class="{ active: currentActive == 5 }"
            @click.native="changeActive(5)"
            >联系我们</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      currentActive: 0,
      isShowMenu: false,
    };
  },
  methods: {
    changeActive(index) {
      this.currentActive = index;
      this.isShowMenu = false;
    },
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
  },
};
</script>

<style scoped lang="less">
@media all and (min-width: 800px) {
  .phone {
    display: none !important;
  }
  .placeholder {
    height: 80px;
    width: 100%;
  }
  .header {
    height: 80px;
    width: 100%;
    background-color: #f4f5fa;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1400px;
      margin: 0 auto;
      .left {
        img {
          width: 170px;
        }
      }
      .center {
        a {
          color: #666666;
          padding: 3px 30px;
          font-size: 20px;
          line-height: 34px;
          margin-right: 5px;
          transition: all 0.2s;
        }
        a:hover {
          background: linear-gradient(139deg, #dc31c0 0%, #8d34e5 100%);
          color: #fff;
        }
        .active {
          background: linear-gradient(139deg, #dc31c0 0%, #8d34e5 100%);
          color: #fff;
        }
      }
      .right {
        width: 145px;
        height: 49px;
        background: linear-gradient(180deg, #dc31c0 0%, #8d34e5 100%);
        box-shadow: 0px 8px 15px rgba(191, 52, 187, 0.5);
        opacity: 1;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        color: #fff;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .placeholder{
    display: none;
  }
  .content {
    display: none;
  }
  
  .phone {
    // margin-bottom: 30px;
    padding-top: 60px;
    position: relative;
    // z-index: 0;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      height: 60px;
      width: 100vw;
      background-color: #f4f5fa;
      position: fixed;
      top: 0;
      z-index: 10;
      box-sizing: border-box;
      .left {
        img {
          width: 80px;
        }
      }
      .right {
        img {
          width: 30px;
        }
      }
    }
    .menu {
      width: 100vw;
      height: 0vh;
      position: fixed;
      top: 0px;
      top: 60px;
      transition: all 0.2s;
      background-color: #fff;
      z-index: 1;
      overflow: hidden;
      a {
        display: inline-block;
        width: 100%;
        padding: 10px;
        padding-left: 20px;
        font-size: 1.5rem;
        border-bottom: 1px solid #eee;
        &:hover {
          color: #bf34bb;
        }
      }
      .active {
        color: #bf34bb;
        background-color: rgb(240, 240, 240);
      }
    }
    .show {
      height: calc(100vh - 60px);
      z-index: 100;
    }
  }
}
</style>
