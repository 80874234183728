<template>
  <div class="attract-investment">
    <div class="left">
      <h2>招商合作</h2>
      <p
        v-for="(item, index) in menu"
        :key="index"
        :class="{ active: index == currentMenu }"
        @click="currentMenu = index"
      >
        {{ item.title }}
      </p>
    </div>
    <div class="right" v-html="menu[currentMenu].content"></div>
  </div>
</template>
<script>
export default {
  name: "AttractInvestment",
  data() {
    return {
      currentMenu: 0,
      menu: [
        {
          title: "招商方案",
          isActive: true,
          content: "",
          content: `
          <img src="https://hm.hmjx.cn/introduce/images/1.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/2.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/3.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/4.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/5.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/6.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/7.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/8.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/9.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/10.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/11.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/12.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/13.jpg" alt="">
          <img src="https://hm.hmjx.cn/introduce/images/14.jpg" alt="">`,
      //     content: `<h2>海购兔代运营服务商招募</h2>
      // <p>
      //   海购兔官方代运营服务商，是指拥有海购兔平台认证，为跨境商家提供专业代运营服务
      //   的优质机构。代理运营服务商需要经过官方认证流程，官方会按季度发布认证服务商名单。
      // </p>
      // <h3>代运营服务领域</h3>
      // <p>1.开店服务</p>
      // <p>2.售前售后服务</p>
      // <p>3.仓储物流对接</p>
      // <p>4.基础运营管理：a.商品上架 b.视觉服务 c.订单处理</p>
      // <p>5.营销服务：a.站内活动 b.站内广告 c.站外广告</p>
      // <p>6.数据分析</p>
      // <h4>一经审核通过，代运营服务商即可享受多重官方福利：</h4>
      // <p>1.官方将优先推荐品牌客户</p>
      // <p>2.官方平台曝光（线上官方网站展示、线下大型活动宣传）</p>
      // <p>3.官方工作人员一对一专业服务，解决合作过程中的问题</p>
      // <p>4.官方定期培训及沟通会议</p>
      // <h3>海购兔官方代运营服务商报名要求</h3>
      // <p>1.必须为中国大陆公司</p>
      // <p>2.有固定的国内办公场所</p>
      // <p>3.公司需具备电商平台代运营经验</p>
      // <p>您可 直接扫描下方二维码，报名参与海购兔代运营合作伙伴项目。</p>`,
        },
      //   {
      //     title: "店主",
      //     isActive: false,
      //     content: `<h2>海购兔代运营服务商招募222</h2>
      // <p>2021-08-20</p>
      // <p>
      //   海购兔官方代运营服务商，是指拥有海购兔平台认证，为跨境商家提供专业代运营服务
      //   的优质机构。代理运营服务商需要经过官方认证流程，官方会按季度发布认证服务商名单。
      // </p>
      // <h3>代运营服务领域</h3>
      // <p>1.开店服务</p>
      // <p>2.售前售后服务</p>
      // <p>3.仓储物流对接</p>
      // <p>4.基础运营管理：a.商品上架 b.视觉服务 c.订单处理</p>
      // <p>5.营销服务：a.站内活动 b.站内广告 c.站外广告</p>
      // <p>6.数据分析</p>
      // <h4>一经审核通过，代运营服务商即可享受多重官方福利：</h4>
      // <p>1.官方将优先推荐品牌客户</p>
      // <p>2.官方平台曝光（线上官方网站展示、线下大型活动宣传）</p>
      // <p>3.官方工作人员一对一专业服务，解决合作过程中的问题</p>
      // <p>4.官方定期培训及沟通会议</p>
      // <h3>海购兔官方代运营服务商报名要求</h3>
      // <p>1.必须为中国大陆公司</p>
      // <p>2.有固定的国内办公场所</p>
      // <p>3.公司需具备电商平台代运营经验</p>
      // <p>您可 直接扫描下方二维码，报名参与海购兔代运营合作伙伴项目。</p>`,
      //   },
      ],
    };
  },
  created() {
    this.content = this.menu[0].content;
  },
  methods: {
   
  },
};
</script>
<style lang="less" scoped>
@media all and (min-width: 800px) {
  .attract-investment {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .left {
      width: 300px;
      background-color: #f1f4f7;
      box-sizing: border-box;
      padding-top: 40px;
      padding-left: 40px;
      p {
        font-size: 20px;
        margin-top: 10px;
        cursor: pointer;
      }
      .active {
        color: #bf34bb;
      }
    }
    /deep/ .right {
      flex: 1;
      padding: 20px;
      padding-left: 40px;
      box-sizing: border-box;
      h2 {
        margin-top: 20px;
        font-size: 26px;
      }
      h3 {
        margin-top: 20px;
        font-size: 22px;
      }
      h4 {
        margin-top: 15px;
        font-size: 20px;
      }
      p {
        margin-top: 3px;
        font-size: 18px;
      }
      img{
        width: 100%;
      }
    }
  }
}
@media all and (max-width: 800px) {
  .attract-investment {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    .left {
      width: 100%;
      background-color: #f1f4f7;
      box-sizing: border-box;
      padding: 20px;

      p {
        font-size: 20px;
        margin-top: 10px;
        cursor: pointer;
      }
      .active {
        color: #bf34bb;
      }
    }
    /deep/ .right {
      flex: 1;
      padding: 20px;
      // padding-left: 40px;
      box-sizing: border-box;
      h2 {
        margin-top: 20px;
      }
      h3 {
        margin-top: 20px;
      }
      h4 {
        margin-top: 15px;
      }
      p {
        margin-top: 10px;
      }
      img{
        width: 100%;
      }
    }
  }
}
</style>

