<template>
  <div class="index">
    <div class="content1">
      <div class="box">
        <div class="left">
          <h1>基于海南全面探索建设自贸岛的趋势</h1>
          <h1>
            海购兔开创性的将<span>轻创业+ <br />跨境电商</span
            >的新商业模式进行运营
          </h1>
          <div @click="$router.push('/settled')">我要入驻</div>
        </div>
        <div class="right">
          <img src="@/assets/index/4.png" alt="" />
        </div>
      </div>
    </div>
    <Title
      title="DUTY FREE ISLAND 海购兔"
      text="海购兔于2021年正式涉足跨境电商（进口）领域，在海南自贸政策的号召下成立了海南商免科技有限公司。
        海购兔与海关监管部门、品牌供应商及第三方贸易主体积极协作，通过「源头直采+免税新零售」的模式，
        汇聚全球正品好货，产品覆盖全部国际一线品牌，并依托海南自贸政策优势，进一步突破IT、仓储以及国际
        国内物流链条的限制。"
    ></Title>
    <!-- <Title title="丰富的商品类目" text=""></Title> -->
    <!-- <div class="goods">
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div class="img-box">
          <img
            src="https://img.4458.cn/product/pic/20210909042111.jpg"
            alt=""
          />
        </div>
      </div>
    </div> -->
    <Title
      title="8大销售生态服务"
      text="携手迈进跨境新领域"
      :textCenter="true"
    ></Title>
    <div class="content2">
      <div class="item">
        <span class="num">1.</span>
        <span class="text">独立技术研发建站</span>
      </div>
      <div class="item">
        <span class="num">5.</span>
        <span class="text">销售运营</span>
      </div>
      <div class="item">
        <span class="num">2.</span>
        <span class="text">海关对接</span>
      </div>
      <div class="item">
        <span class="num">6.</span>
        <span class="text">品牌推广</span>
      </div>
      <div class="item">
        <span class="num">3.</span>
        <span class="text">全球货源采购</span>
      </div>
      <div class="item">
        <span class="num">7.</span>
        <span class="text">培训管理</span>
      </div>
      <div class="item">
        <span class="num">4.</span>
        <span class="text">渠道拓展</span>
      </div>
      <div class="item">
        <span class="num">8.</span>
        <span class="text">客户服务</span>
      </div>
    </div>
    <Title
      title="专业跨境解决方案"
      text="让跨境出海更简单"
      :textCenter="true"
      :purpleBg="true"
    ></Title>
    <div class="content3">
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon2.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>跨境清关业务</h3>
          <div class="p-box">
            <p>海关报关对接，</p>
            <p>支持“三单对碰”</p>
            <p>和“179接口”</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon1.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>海量云仓</h3>
          <div class="p-box">
            <p>专业采购团队，全球直采，提供</p>
            <p>100+国家，1000+品牌、10000+sku</p>
            <p>的云端仓库。铺设高效物流网</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon3.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>强大管理平台</h3>
          <div class="p-box">
            <p>后台提供批量上新、订单追踪、</p>
            <p>销售报表等功能</p>
            <p>一站式免满足店铺管理需求</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon4.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>本地服务支持</h3>
          <div class="p-box">
            <p>提供专业运营团队，客户服务。</p>
            <p>保证客户无忧购物，给予平台</p>
            <p>营销策略</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon5.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>定制化服务</h3>
          <div class="p-box">
            <p>专业技术团队</p>
            <p>提供个性化定制服务</p>
            <p>提供专业解决</p>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="top">
          <div class="img-box">
            <img src="@/assets/index/icon6.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <h3>API 对接</h3>
          <div class="p-box">
            <p>提供多种对接方式</p>
            <p>轻松开店</p>
          </div>
        </div>
      </div>
    </div>
    <Title
      title="现在入驻专享3大权益"
      text="轻松开启跨境贸易"
      :textCenter="true"
      :greyBg="true"
    ></Title>
    <div class="content4">
      <div class="item-box">
        <div class="item">
          <div class="top">
            <span>店铺免费装修</span>
            <span>01</span>
          </div>
          <div class="bottom">
            配备专业招商经理细致解答各种疑问 跟进入驻流程，贴心为您服务
          </div>
        </div>
        <div class="item">
          <div class="top">
            <span>免服务费</span>
            <span>02</span>
          </div>
          <div class="bottom">
            跨境卖家享有大量活动曝光资源 助您轻松起步，快速成长
          </div>
        </div>
        <div class="item">
          <div class="top">
            <span>丰富的营销工具</span>
            <span>03</span>
          </div>
          <div class="bottom">
            配备专业招商经理细致解答各种疑问 跟进入驻流程，贴心为您服务
          </div>
        </div>
      </div>
    </div>
    <Title
      title="品牌展示"
      text="全球一线大牌 更多购买选择"
      :textCenter="true"
    ></Title>
    <div class="content5">
      <div class="item">
        <img src="@/assets/index/brand/1.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/2.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/3.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/4.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/5.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/6.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/7.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/8.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/9.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/10.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/11.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/12.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/13.png" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/14.jpg" alt="" />
      </div>
      <div class="item">
        <img src="@/assets/index/brand/15.jpeg" alt="" />
      </div>
    </div>
    <Title
      title="立即加入，与海购兔共享成长！"
      :textCenter="true"
      :purpleBg="true"
      :isShowBtnStyle="true"
      btnText="立即入驻"
    ></Title>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
export default {
  components: { Title },
  name: "index",
};
</script>
<style lang="less" scoped>
@media all and (min-width: 800px) {
  .content1 {
    width: 100%;
    background-color: #f4f5fa;
    .box {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .left {
      h1 {
        font-size: 40px;
        font-weight: 500;
        line-height: 68px;
        color: #333333;
        &:nth-child(2) {
          margin: 65px 0;
        }
        span {
          color: #c853c5;
          font-size: 50px;
        }
      }
      div {
        width: 283px;
        height: 58px;
        background: linear-gradient(180deg, #dc31c0 0%, #8d34e5 100%);
        box-shadow: 0px 10px 20px rgba(191, 52, 187, 0.5);
        border-radius: 50px;
        font-size: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .right {
      img {
        width: 731px;
        height: 662px;
      }
    }
  }
  .content2 {
    width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 569px;
      height: 135px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      margin-bottom: 46px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 185px;
        height: 137px;
        right: 0;
        top: 0;
        background: url("~@/assets/index/1.png") no-repeat;
        background-size: cover;
        z-index: -1;
      }
      .num {
        font-size: 80px;
        font-weight: 500;
        line-height: 170px;
        color: #bf34bb;
      }
      .text {
        font-size: 40px;
        font-weight: 400;
        line-height: 80px;
        color: #333333;
        margin-top: 20px;
      }
    }
  }
  .content3 {
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      margin-bottom: 20px;

      .top {
        width: 387px;
        height: 122px;
        background: #edeef5;
        display: flex;
        align-items: center;
        justify-content: center;
        .img-box {
          width: 87px;
          height: 87px;
          background: #dde0ee;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 64px;
          }
        }
      }
      .bottom {
        width: 387px;
        height: 268px;
        background: #fbf4fa;
        text-align: center;
        h3 {
          font-size: 35px;
          font-weight: 500;
          // line-height: 60px;
          color: #c853c5;
          padding-top: 37px;
          margin-bottom: 20px;
        }
        .p-box {
          p {
            font-size: 22px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 37px;
            color: #666666;
          }
        }
      }
    }
  }
  .content4 {
    width: 100%;
    background: #f2f5fa;
    .item-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .item {
        width: 33%;
        box-sizing: border-box;
        padding: 60px 10px;
        .top {
          display: flex;
          align-items: center;
          span {
            &:nth-child(1) {
              font-size: 30px;
              font-weight: 500;
              line-height: 51px;
              color: #c853c5;
            }
            &:nth-child(2) {
              font-size: 100px;
              font-weight: 500;
              line-height: 100px;
              color: #f2e4f2;
              margin-left: 20px;
            }
          }
        }
        .bottom {
          font-size: 22px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .content5 {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 50px;
    .item {
      width: 200px;
      height: 130px;
      border: 1px solid #999999;
      display: flex;
      align-content: center;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .goods{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item{
      margin: 20px;
      .img-box{
        width: 220px;
        height: 220px;
        display: flex;
        align-items: center;
        overflow: hidden;
        img{
          width: 100%;
        }
      }
    }
  }
}
@media all and (max-width: 800px) {
  .content1 {
    width: 100%;
    padding-top: 50px;
    .box {
      .left {
        h1 {
          font-size: 1.3rem;
          text-align: center;
          width: 50%;
          margin: 0 auto;
          line-height: 150%;
          color: #333333;
          &:nth-child(2) {
            width: 70%;
            margin-top: 10px;
            span {
              font-size: 1.5rem;
              color: #bf34bb;
            }
          }
        }
        div {
          width: 150px;
          margin: 0 auto;
          margin-top: 30px;
          height: 40px;
          background: linear-gradient(180deg, #dc31c0 0%, #8d34e5 100%);
          box-shadow: 0px 10px 20px rgba(191, 52, 187, 0.5);
          border-radius: 20px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .right {
        img {
          width: 100%;
        }
      }
    }
  }
  .content2 {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 90%;
      height: 80px;
      margin: 0 auto;
      padding-left: 20px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 20px;
      position: relative;
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 5;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 6;
      }
      &:nth-child(5) {
        order: 3;
      }
      &:nth-child(6) {
        order: 7;
      }
      &:nth-child(7) {
        order: 4;
      }
      &:nth-child(8) {
        order: 8;
      }
      &::after {
        content: "";
        position: absolute;
        width: 95px;
        height: 77px;
        right: 0;
        top: 0;
        background: url("~@/assets/index/1.png") no-repeat;
        background-size: cover;
        z-index: -1;
      }
      .num {
        font-size: 4rem;
        font-weight: 500;
        line-height: 120px;
        color: #bf34bb;
      }
      .text {
        font-size: 2rem;
        font-weight: 400;
        line-height: 85px;
        color: #333333;
        margin-top: 20px;
      }
    }
  }
  .content3 {
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      .top {
        .img-box {
          padding: 10px 20px;
          img {
            width: 80px;
          }
        }
      }
    }
  }
  .content4 {
    width: 100%;
    background: #f2f5fa;
    .item-box {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .item {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 10px;
        .top {
          display: flex;
          align-items: center;
          span {
            &:nth-child(1) {
              font-size: 26px;
              font-weight: 500;
              line-height: 51px;
              color: #c853c5;
            }
            &:nth-child(2) {
              font-size: 100px;
              font-weight: 500;
              line-height: 100px;
              color: #f2e4f2;
              margin-left: 20px;
            }
          }
        }
        .bottom {
          font-size: 20px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .content5 {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 50px;
    .item {
      width: 40%;
      height: 80px;
      border: 1px solid #999999;
      display: flex;
      align-content: center;
      margin-bottom: 20px;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}
</style>
